import { Box } from "@chakra-ui/core"
import React from "react"
import { Helmet } from "react-helmet"
import { useProducts } from "../../../../hooks/useProducts"

type Props = {
  slug: string
  productFilter?:
    | "offers"
    | null
    | "tuulivoima"
    | "vesivoima"
    | "aurinkovoima"
    | undefined
}

const iframe = `<script type="text/javascript" src="https://static.ascontentcloud.com/comparisonfeed/resources/js/iframeResizer.min.js"></script><iframe id="as-comparison-iframe-126052293" style="border: none; width: 1px; min-width: 100%; overflow: hidden; " scrolling="no" src="https://feed.aservice.tools/v2/public/publisher/comparisonfeed/iframe/sahkoa" onload="iFrameResize({heightCalculationMethod: 'taggedElement', checkOrigin: false}, this);"></iframe>`

export const OfferContainer: React.FC<Props> = ({ productFilter, slug }) => {
  const plans = useProducts(productFilter)
  // const planCards = plans.map((plan: { node: ElectricPlan }, i: number) => (
  //   <OfferCard key={i} electricPlan={plan.node} slug={slug} />
  // ))
  return (
    <Box>
      <Box display={["flex", "flex", "flex", "flex", "none", "none"]}>
        <a
          href="https://aslinkhub.com/?bid=2493673&media_id=87256"
          target="_blank"
        >
          <img src="https://impr.adservicemedia.dk/cgi-bin/Services/ImpressionService/Image.pl?bid=2493673&media_id=87256" />
        </a>
      </Box>
      <Box display={["none", "none", "none", "none", "flex", "flex"]}>
        <a
          href="https://aslinkhub.com/?bid=2493669&media_id=87256"
          target="_blank"
        >
          <img src="https://impr.adservicemedia.dk/cgi-bin/Services/ImpressionService/Image.pl?bid=2493669&media_id=87256" />
        </a>
      </Box>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.ascontentcloud.com/comparisonfeed/resources/js/iframeResizer.min.js"
        ></script>
      </Helmet>
      <Box dangerouslySetInnerHTML={{ __html: iframe }} />
    </Box>
  )
}
