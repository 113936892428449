import { useStaticQuery, graphql } from "gatsby"
import { ElectricPlan } from "../types/ElectricPlan"

export const useProducts = (
  filter:
    | "offers"
    | null
    | "tuulivoima"
    | "vesivoima"
    | "aurinkovoima"
    | "pörssisähkö"
    | undefined
) => {
  const data = useStaticQuery(graphql`
    query AllProductsQuery {
      products: allElectricCompaniesJson {
        edges {
          node {
            name
            planName
            type
            priceKwh
            discountPriceKwh
            discountMonthPrice
            discountLength
            maxUse
            priceMonth
            contractLength
            benefits
            coalFree
            discount
            supply
            url
            img
            affiliate
            color
            priority
          }
        }
      }
    }
  `)

  if (filter === "offers")
    return data.products.edges.filter(
      (p: { node: ElectricPlan }) => p.node.discount
    )
  if (filter === "pörssisähkö")
    return data.products.edges.filter(
      (p: { node: ElectricPlan }) => p.node.type === "pörssisähkö"
    )
  if (filter === "aurinkovoima")
    return data.products.edges.filter((p: { node: ElectricPlan }) =>
      p.node.supply.includes("aurinkovoima")
    )
  if (filter === "tuulivoima")
    return data.products.edges.filter((p: { node: ElectricPlan }) =>
      p.node.supply.includes("tuulivoima")
    )

  return data.products.edges
}
