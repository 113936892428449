import { Box, Button, PseudoBox } from "@chakra-ui/core"
import React from "react"

type Props = {
  text: string
  color?: string
  bg?: string
}

export const ActionButton: React.FC<Props> = ({ text, color, bg }) => {
  return (
    <PseudoBox
      background={bg ? bg : "#1c72d9"}
      textDecoration="none"
      border="none"
      p="1rem 2rem"
      borderRadius="10px"
      fontSize="1.2rem"
      fontWeight="600"
      marginTop="0.3rem"
      textAlign="center"
      maxW="250px"
      cursor="pointer"
      color={color ? color : "white"}
      _hover={{
        outline: "none",
        bg: `actionHover`,
        boxShadow: "outline",
        transition: "all 1s",
        borderColor: "gray.300",
      }}
    >
      {text}
    </PseudoBox>
  )
}
