import { Text } from "@chakra-ui/core"
import React from "react"

type Props = { text: string }

export const Slogan: React.FC<Props> = ({ text }) => {
  return (
    <Text
      fontSize="1.3rem"
      color="slogan"
      marginY="0rem"
      marginTop="2rem"
      fontWeight="700"
    >
      {text}
    </Text>
  )
}
