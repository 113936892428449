import { Box, Heading, Button } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"
import { useChildren } from "../../hooks/useChildren"

type Props = { parent: string; pageName: string }

export const RelatedLinks: React.FC<Props> = ({ parent, pageName }) => {
  const relatedLinks = useChildren(parent).filter(
    (page: any) => page.node.frontmatter.name !== pageName
  )
  if (relatedLinks.length === 0) return <></>
  return (
    <Box
      p={[2, 4, 4, 4]}
      marginX="auto"
      width={[
        "95%", // base
        "90%", // 480px upwards
        "75%", // 768px upwards
        "60%", // 992px upwards
      ]}
    >
      <Heading as="h4" fontSize="1.3rem" textAlign="center">
        Myös nämä saattavat kiinnostaa
      </Heading>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {relatedLinks.map((page: any) => {
          return (
            <Link
              to={page.node.fields.fixedPath}
              key={page.node.frontmatter.path}
            >
              <Button
                border="none"
                color="white"
                cursor="pointer"
                bg="action"
                margin="1rem"
              >
                {page.node.frontmatter.slug}
              </Button>
            </Link>
          )
        })}
      </Box>
    </Box>
  )
}
