import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = {}

export const Heading3: React.FC<Props> = ({ children }) => {
  return (
    <Heading
      as="h3"
      fontSize={["1.3rem", "1.4rem", "1.5rem", "1.6rem"]}
      fontWeight="bold"
      letterSpacing="0.3rem"
      color="# 21217C"
      marginY="0.5rem"
    >
      {children}
    </Heading>
  )
}
