import React from "react"
import { Box, Heading, Tag, Text, Image, Divider } from "@chakra-ui/core"
import { Layout } from "../../components/layout/Layout"
import { graphql, Link } from "gatsby"
import rehypeReact from "rehype-react"

import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"

import { Seo } from "../../components/seo/Seo"
import { useChildren } from "../../hooks/useChildren"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"

import { NormalHeadingContainer } from "../../components/templates/main/heading/NormalHeadingContainer"

import { OfferContainer } from "../../components/templates/main/heading/OfferContainer"
import { Heading3 } from "../../components/headings/Heading3"
import { RelatedLinks } from "../../components/buttons/RelatedLinks"

type Props = {
  data: any
}
// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ data }) => {
  const {
    title,
    metaTitle,
    metaDesc,
    sidebar,
    slug,
    offers,
    productFilter,
    slogan,
    firstButton,
    secondButton,
    secondButtonUrl,
    cardsTitle,
    parent,
    name,
    image,
    desc,
  } = data.markdownRemark.frontmatter

  const children = useChildren(slug)

  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })

  return (
    <Layout>
      <Seo title={metaTitle} desc={metaDesc} />
      <NormalHeadingContainer
        mainTitle={title}
        slogan={slogan}
        slug={slug}
        desc={desc}
        image={image}
        actionLink="https://sahkovertailu.fi/"
        firstButton={firstButton}
        secondButton={secondButton}
        secondButtonUrl={secondButtonUrl}
        offers={offers}
      >
        <Divider color="#dfdfdf" />
        <Link to="https://www.cvpohjat.fi/" target="_blank">
          <Box color="blue">
            <Tag
              marginRight="1rem"
              marginTop="0.3rem"
              background="blue"
              color="white"
            >
              Mainos
            </Tag>

            <Text>
              Tee toimiva CV pohja helposti ja nopeasti osoitteessa CVpohjat.fi
            </Text>
          </Box>
        </Link>
        <Divider color="#dfdfdf" />

        {offers && (
          <Box>
            <Box
              marginY="2rem"
              marginLeft="1rem"
              textAlign={["center", "left"]}
            >
              <a id="tulokset">
                <Heading3>{cardsTitle}</Heading3>
              </a>
            </Box>
            <OfferContainer productFilter={productFilter} slug={slug} />
          </Box>
        )}
      </NormalHeadingContainer>
      <RelatedLinks parent={parent} pageName={name} />
      <Divider color="#dfdfdf" />
      <Link to="https://www.omaurakka.fi/" target="_blank">
        <Box color="blue">
          <Tag
            marginRight="1rem"
            marginTop="0.3rem"
            background="blue"
            color="white"
          >
            Mainos
          </Tag>

          <Text>
            Kilpailuta paras remontti vain parissa minuutissa -- Omaurakka.fi
          </Text>
        </Box>
      </Link>
      <Divider color="#dfdfdf" />
      <MainContainer>
        <Box flex="8">
          <TextContainer>
            {renderAst(data.markdownRemark.htmlAst)}
          </TextContainer>
        </Box>
        {sidebar && (
          <SidebarRight>
            <FixedSidebar>
              <Heading as="h3">Sivupalkki</Heading>
            </FixedSidebar>
          </SidebarRight>
        )}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        offers
        firstButton
        secondButton
        name
        productFilter
        secondButtonUrl
        sidebar
        cardsTitle
        slogan
        image
        slug
        metaTitle
        parent
        metaDesc
        desc
      }
    }
  }
`

export default Template
