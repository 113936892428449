import { Text } from "@chakra-ui/core"
import React from "react"

type Props = {
  desc: string
  color: string
}

export const DescText: React.FC<Props> = ({ desc, color }) => {
  return (
    <Text
      fontSize="1.4rem"
      lineHeight="2rem"
      fontWeight="300"
      color={color ? color : "white"}
      marginY="3rem"
      maxW={["100%", "90%"]}
    >
      {desc}
    </Text>
  )
}
