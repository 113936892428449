import { Box, Image, Text } from "@chakra-ui/core"
import React from "react"
import { ActionButton } from "../../../buttons/ActionButton"
import { AffiliateLink } from "../../../buttons/AffiliateLink"
import { Heading1 } from "../../../headings/Heading1"
import { MenuBar } from "../../../navigation/menuBar/MenuBar"
import { DescText } from "../../../texts/DescText"
import { Slogan } from "../../../texts/Slogan"
import { Link } from "gatsby"

type Props = {
  mainTitle: string
  slogan: string
  desc: string
  actionLink?: string
  image?: string
  slug: string
  firstButton?: string
  secondButton?: string
  secondButtonUrl?: string
  offers: boolean
}

export const NormalHeadingContainer: React.FC<Props> = ({
  children,
  mainTitle,
  desc,
  actionLink,
  image,
  slogan,
  slug,
  firstButton,
  secondButtonUrl,
  secondButton,
  offers,
}) => {
  return (
    <Box
      background="linear-gradient(180deg, #F1FDFF 0%, #6CB8D0 100%)"
      padding={["1rem 1.5rem", "1rem 3rem", "1rem 6rem", "1rem 10rem"]}
      borderRadius="0px 0px 300px 0px"
      marginBottom="rem"
      marginX="auto"
    >
      <MenuBar />
      <Box marginTop="1rem" marginBottom="0px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign={["left", "left", "left"]}
          flexDir={["column", "column", "column", "row"]}
        >
          <Box flex="5">
            <Slogan text={slogan} />
            <Heading1 color="#002D35">{mainTitle}</Heading1>
            <DescText color="black" desc={desc} />
            {/* {actionLink && firstButton && (
              <Box>
                <AffiliateLink
                  name={`sahkomittari-button`}
                  clickCategory="zmarta"
                  slug={slug}
                >
                  <ActionButton text={firstButton} />
                </AffiliateLink>
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom="1rem"
                  marginLeft={["0.5rem", "0.5rem", "1rem"]}
                  justifyContent={["center", "center", "left"]}
                >
                  <Text as="span" fontSize="0.8rem">
                    Kilpailutuksen tarjoaa
                  </Text>
                  <AffiliateLink
                    name={`sahkomittari-logo`}
                    slug={slug}
                    clickCategory="sahkomittariLogo"
                  >
                    <Image
                      width="140px"
                      marginTop="0.5rem"
                      src="https://ik.imagekit.io/n3kwf1sgwl/logo__1_.png"
                      alt="zmarta sähkövertailu"
                    />
                  </AffiliateLink>
                </Box>
              </Box>
            )} */}

            <Link to={"https://www.kilpailutasahkosopimus.fi/#tulokset"}>
              <ActionButton
                text={"Katso tarjoukset"}
                color="mainFont"
                bg="white"
              />
            </Link>
          </Box>
          <Box flex="1" minH="300px">
            <Image
              alt={mainTitle}
              margin="0px"
              p="0px"
              right="0p"
              width={["300px", "400px", "500px", "650px"]}
              src={image}
              position="relative"
              top="0px"
              marginBottom={["0rem", "0rem", "-10.5rem"]}
              marginRight={["0rem", "0rem", "0rem", "-8rem"]}
            />
          </Box>
        </Box>
      </Box>
      <Box
        marginY="0rem"
        position="relative"
        top="0px"
        marginBottom={!offers ? "1rem" : "-10rem"}
      >
        {children}
      </Box>
    </Box>
  )
}
